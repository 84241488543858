import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const GallerySection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Sample gallery items - replace image URLs with your actual project images
  const galleryItems = [
    {
      title: "Network Installation",
      description: "Professional network setup for corporate clients",
      image: "/gallery/cideckenya_1.jpg"
    },
    {
      title: "Fiber Optic Solutions",
      description: "High-speed fiber optic cabling installations",
      image: "/gallery/cidec_kenya_fiber.jpg"
    },
    {
      title: "Security Systems",
      description: "Advanced security camera installations",
      image: "/gallery/cideckenya_3.jpg"
    },
    {
      title: "Smart Home Setup",
      description: "Comprehensive smart home automation",
      image: "/gallery/cideckenya_4.jpg"
    }
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === galleryItems.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? galleryItems.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section id="gallery" className="py-20 bg-gradient-to-b from-white to-emerald-50/50">
      <div className="max-w-7xl mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Projects</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Explore our recent installations and solutions
          </p>
        </div>

        <div className="relative">
          <div className="overflow-hidden rounded-xl shadow-xl aspect-[16/9] bg-gray-100">
            <div className="relative">
              <img
                src={galleryItems[currentIndex].image}
                alt={galleryItems[currentIndex].title}
                className="w-full h-full object-cover"
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-8">
                <h3 className="text-2xl font-bold text-white mb-2">
                  {galleryItems[currentIndex].title}
                </h3>
                <p className="text-emerald-200">
                  {galleryItems[currentIndex].description}
                </p>
              </div>
            </div>
          </div>

          {/* Navigation Buttons */}
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg backdrop-blur-sm transition-all duration-300"
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg backdrop-blur-sm transition-all duration-300"
          >
            <ChevronRight size={24} />
          </button>

          {/* Dots Navigation */}
          <div className="flex justify-center mt-4 space-x-2">
            {galleryItems.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${
                  currentIndex === index 
                    ? 'bg-emerald-600 w-6' 
                    : 'bg-gray-300 hover:bg-emerald-400'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
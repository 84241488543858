import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Button, Input, Textarea } from './App';

const ContactFormModal = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://core.cideckenya.co.ke/contact/inquiry.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
      setShowModal(true);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6">
        <Input 
          name="name" 
          placeholder="Your Name" 
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input 
          name="email" 
          type="email" 
          placeholder="Your Email" 
          value={formData.email}
          onChange={handleChange}
          required
        />
        <Textarea 
          name="message" 
          placeholder="Your Message" 
          value={formData.message}
          onChange={handleChange}
          required
        />
        <Button 
          type="submit" 
          className="w-full" 
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </Button>
      </form>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="bg-white rounded-xl p-8 max-w-md w-full relative">
            <Button 
              variant="ghost" 
              size="icon" 
              className="absolute top-4 right-4"
              onClick={() => setShowModal(false)}
            >
              <X size={24} />
            </Button>
            {submitStatus === 'success' ? (
              <div className="text-center">
                <h3 className="text-2xl font-bold text-emerald-600 mb-4">Message Sent!</h3>
                <p className="text-gray-600 mb-6">
                  Thank you for reaching out. We'll get back to you soon.
                </p>
                <Button onClick={() => setShowModal(false)}>Close</Button>
              </div>
            ) : (
              <div className="text-center">
                <h3 className="text-2xl font-bold text-red-600 mb-4">Submission Failed</h3>
                <p className="text-gray-600 mb-6">
                  There was an issue sending your message. Please try again later.
                </p>
                <Button onClick={() => setShowModal(false)}>Close</Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ContactFormModal;
